import { PlanRanges } from '@pixcap/ui-core/models/store/user.interface';
import { ContextMenu } from '@pixcap/ui-core/models/widgets/contextMenuManager.interface';

export enum UserDeviceTypes {
	MOBILE = 'mobile',
	TABLET = 'tablet',
	DESKTOP = 'desktop',
}

export enum NotificationType {
	ERROR = 'error',
	SUCCESS = 'success',
	INFO = 'info',
	WARN = 'warn',
}

export enum ABTestingCases {
	UPGRADE_CTA_TEST = 'UPGRADE_CTA_TEST',
}

export type TopicItemType = {
	topicId?: string;
	topicName: string;
	topicKey: string;
	topicSlug?: string;
	thumbnailUrl?: string;
	dimension?: string;
	sequence?: number;
};

export enum SortBy {
	ALPHABETICAL = 'alphabetical',
	OLDEST = 'oldest',
	RECENT = 'recent',
	RELEVANCE = 'relevance',
	PROJECT_STATUS = 'status',
}

export enum SortOrders {
	ASC = 'ASC',
	DESC = 'DESC',
}

export type SortByOption = {
	name: SortBy;
	text: string;
};

export type Popup = {
	show: boolean;
	template: null | string;
};

export type NetworkStatusModal = {
	showModal: boolean;
	networkConnected: boolean;
} | null;

export enum RefreshAction {
	REFRESH = 'REFRESH',
	REDIRECT = 'REDIRECT',
}

export enum SpecialPromotionType {
	FREEBIES = 'FREEBIES',
	DISCOUNT_PROMO = 'DISCOUNT_PROMO',
	CODE_DISCOUNT_PROMO = 'CODE_DISCOUNT_PROMO',
}

export enum TopicResourceType {
	SCENES = 'SCENES',
	DESIGN_TEMPLATE = 'DESIGN_TEMPLATES',
}

export type RefreshNotice = {
	message: string;
	action: RefreshAction;
};

export type LoadingStatus = {
	message: string;
	progress?: string;
};

export type HSLA = {
	h: number;
	s: number;
	l: number;
	a?: number;
};

export type PaginatedDto<T> = {
	content?: T[];
	page?: number;
	totalPages?: number;
	totalItems?: number;
};

export type PaginationBaseType<ItemType> = {
	listOfItems?: ItemType[];
	currentPage?: number;
	totalPages?: number;
	totalItems?: number;
	pageSize?: number;
};

export type CustomPaginationBaseType<ListType> = {
	listOfItems?: ListType;
	currentPage?: number;
	totalPages?: number;
	totalItems?: number;
	pageSize?: number;
};

export type DropdownByOption<T> = {
	name: T;
	text: string;
};

export const NAMESPACE = 'app';

export type Promotion = {
	promotionName?: string;
	startDate: string;
	expiredDate: string;
	discount?: number;
	promotionCode?: string;
	eventBannerTitle?: string;
	promoBannerTitle?: string;
	pricingTitle?: string;
	pricingDescription?: string;
	planApply?: PlanRanges | string;
	type: SpecialPromotionType | string;
};

export type CodePromotion = {
	startDate: string;
	expiredDate: string;
	discount: number;
	promotionCode: string;
	type: SpecialPromotionType | string;
};

export type Topics = {
	[resourceType in TopicResourceType]: TopicItemType[];
} & {
	page: number;
	totalPages: number;
	totalItems: number;
};

export type AppState = {
	locale: string;
	refreshNotice: RefreshNotice;
	networkStatusModal: NetworkStatusModal;
	loadingStatus: null | LoadingStatus;
	engineLoadingStatus: null | LoadingStatus;
	showAppSuspense: boolean;
	isWebsocketConnected: boolean;
	isAppWindowActive: boolean;
	isReconnectingSocket: boolean;
	isLightMode: boolean;
	isMobileView: boolean;
	showLayoutFooter: boolean;
	showLayoutHeader: boolean;
	showOverlayOnMobileEditor: boolean;
	isLightTheme: boolean;
	showPixRenderAnnouncement: boolean;
	contextMenu: ContextMenu;
	newsbanner?: {
		description: string;
		link?: {
			text: string;
			href: string;
		};
		action?: string;
	};
	abTesting: { id: string; [key: string]: string };
	isFetchingTopics: boolean;

	topics:
		| Topics
		| {
				page: number;
				totalPages: number;
				totalItems: number;
		  };
};

export type DefaultError = Error | string | null;

export interface DefaultInteractingPromiseState {
	loading: boolean;
	error: DefaultError;
}

export enum MutationTypes {
	SET_REFRESH_NOTICE = 'SET_REFRESH_NOTICE',
	SET_NETWORK_STATUS_MODAL = 'SET_NETWORK_STATUS_MODAL',
	SET_LOADING_STATUS = 'SET_LOADING_STATUS',
	SET_ENGINE_LOADING_STATUS = 'SET_ENGINE_LOADING_STATUS',
	SET_IS_WEBSOCKET_CONNECTED = 'SET_IS_WEBSOCKET_CONNECTED',
	SET_SHOW_APP_SUSPENSE = 'SET_SHOW_APP_SUSPENSE',
	SET_IS_APP_WINDOW_ACTIVE = 'SET_IS_APP_WINDOW_ACTIVE',
	SET_IS_RECONNECTING_SOCKET = 'SET_IS_RECONNECTING_SOCKET',
	SET_IS_LIGHT_MODE = 'SET_IS_LIGHT_MODE',
	SET_IS_MOBILE_VIEW = 'SET_IS_MOBILE_VIEW',
	SET_APP_NEWS_BANNER = 'SET_APP_NEWS_BANNER',
	SET_CONTEXT_MENU = 'SET_CONTEXT_MENU',
	SET_AB_TESTING = 'SET_AB_TESTING',

	SET_IS_FETCHING_TOPICS = 'SET_IS_FETCHING_TOPICS',
	SET_SHOW_OVERLAY_ON_MOBILE_EDITOR = 'SET_SHOW_OVERLAY_ON_MOBILE_EDITOR',
	UPDATE_TOPICS = 'UPDATE_TOPICS',
	SET_APP_LOCALE = 'SET_APP_LOCALE',
}

export enum ActionTypes {
	SET_APP_THEME = 'SET_APP_THEME',
	SET_APP_LOCALE = 'SET_APP_LOCALE',
	GET_TOPICS = 'GET_TOPICS',
}

export enum FreebiesTypes {
	WATERMARK_FREE = 'WATERMARK_FREE_RENDERING',
}

export enum GetterTypes {
	CURRENT_PROMOTION = 'currentPromotion',
	CODE_PROMOTION = 'codePromotion',
}

export type Getters<S = AppState> = {
	currentPromotion: (state: S) => Promotion | null;
	codePromotion: (state: S) => CodePromotion | null;
};
