/* eslint-disable import/no-mutable-exports */
import axios, { AxiosRequestConfig } from 'axios';
import type { Store } from 'vuex';
import { BaseHttpClient } from '@pixcap/ui-core/services/httpclient/BaseHttpClient';
import {
	NAMESPACE as AUTH_NAMESPACE,
	ActionTypes as AuthActionTypes,
	GetterTypes as AuthGetterTypes,
	IAuthState,
} from '@pixcap/ui-core/models/store/auth.interface';
import { mutationsWrapper as AppMutations } from '@pixcap/ui-core/store/app';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { $notify } from '@pixcap/ui-core/helpers/notification';
import logger from '@pixcap/ui-core/helpers/logger';
import { NAMESPACE as APP_NAMESPACE, AppState, NotificationType } from '@pixcap/ui-core/models/store/app.interface';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
import { getCookie } from '@pixcap/ui-core/utils/WindowUtils';
import { C_SESSION_COOKIE_KEY } from '@pixcap/ui-core/constants/app.constants';
import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
import { DEFAULT_APP_LOCALE } from '@pixcap/explore/app/constants/app.constants';

export class HttpClient extends BaseHttpClient {
	private _refreshTokenPromise: Promise<any> | null; // this holds any in-progress token refresh requests
	private _isConnectedToNetwork = true;
	private _retryRequestsQueue: { config: any; resolve: any }[] = [];
	private _refreshTokenQueue: { resolve?: any; reject?: any }[] = [];
	private _store;
	isClientEnv = false; // used to separate server env from client env

	constructor(config: { baseURL: string; isClient: boolean }, store: Store<any>, axiosConfig: AxiosRequestConfig = {}) {
		super({
			baseURL: config.baseURL,
			headers: {
				'Content-Type': 'application/json',
			},
			...axiosConfig,
			withCredentials: true,
		});
		this.isClientEnv = config.isClient;
		this._store = store;
		if (this.isClientEnv) {
			if (!_PIXCAP_ENV.IS_LOCAL_APP_ENV) this._isConnectedToNetwork = window.navigator.onLine;
			this.registerConnectionStatusListener();
		}
		this._initNetworkStatusInterceptor();
		this._initAuthInterceptor();
		this._initLocaleInterceptor();
		this._initResponseDurationInterceptor();
		this._initCancelTokenErrorInterceptor();
		this._initErrorInterceptor();
	}

	get isAuthenticated() {
		return this._store.getters[`${AUTH_NAMESPACE}/${AuthGetterTypes.IS_AUTHENTICATED}`];
	}

	get hasAccessToken() {
		return this.isClientEnv ? getCookie(document.cookie, C_SESSION_COOKIE_KEY) != null : true;
	}

	get projectEndpointBasePath() {
		return this.isAuthenticated ? 'project' : 'anonymous_project';
	}

	async refreshToken(originalRequest?: AxiosRequestConfig) {
		return new Promise((resolve, reject) => {
			this._refreshTokenPromise = this._store.dispatch(`${AUTH_NAMESPACE}/${AuthActionTypes.REFRESH}`, undefined, { root: true });
			this._refreshTokenPromise.then((isSuccessfull: boolean) => {
				this._refreshTokenPromise = null;
				this._retryRefreshTokenRequests(false);
				resolve(isSuccessfull);
			});

			if (originalRequest) {
				this._refreshTokenPromise.then(() => {
					const authState: IAuthState = this._store.state[`${AUTH_NAMESPACE}`];
					const token = authState.idToken;
					if (token) {
						originalRequest.headers['Authorization'] = 'Bearer ' + token;
					}
					return this._client.request(originalRequest);
				});
			}

			this._refreshTokenPromise.catch(async () => {
				await AuthActions.logout(this._store);
				window.location.href = _PIXCAP_ENV.LOGIN_URL;
			});
		});
	}

	private _retryOfflineRequests() {
		this._retryRequestsQueue.forEach(({ config, resolve }) => {
			resolve(config);
		});
		this._retryRequestsQueue = [];
	}

	private _retryRefreshTokenRequests(refreshFailed: boolean) {
		this._refreshTokenQueue.forEach(({ resolve, reject }) => {
			if (refreshFailed) reject();
			else resolve();
		});
		this._refreshTokenQueue = [];
	}

	/**
	 * Injects JWT token from session storage as bearer token in auth header.
	 */
	private _initAuthInterceptor() {
		const authInterceptor = async (config) => {
			if (config.method.toUpperCase() === 'GET' && config.url.startsWith(API_PATHS.BLOG_PATH)) {
				return config;
			}
			if (this._refreshTokenPromise && config.url != `${API_PATHS.AUTH_PATH}/refresh`) {
				const refreshTokenIsLoading = async () => {
					return new Promise((resolve, reject) => {
						this._refreshTokenQueue.push({ resolve, reject });
					});
				};
				await refreshTokenIsLoading();
			}
			const authState: IAuthState = this._store.state[`${AUTH_NAMESPACE}`];
			const token = authState.idToken;
			if (token) {
				if (config.headers == null) config.headers = {};
				config.headers.Authorization = 'Bearer ' + token;
			}
			return config;
		};
		this._client.interceptors.request.use(authInterceptor);
	}

	private _initNetworkStatusInterceptor() {
		const networkStatusInterceptor = (config) => {
			if (config.requestRetries == null) config.requestRetries = 3;
			if (this._isConnectedToNetwork) return config;
			return new Promise((resolve) => {
				this._retryRequestsQueue.push({ config, resolve });
			});
		};

		this._client.interceptors.request.use(networkStatusInterceptor);
	}

	/**
	 * Returns response duration in duration property of response.
	 */
	private _initResponseDurationInterceptor() {
		const requestStartTimeInterceptor = (config) => {
			config.metadata = {
				startTime: new Date(),
			};
			return config;
		};
		this._client.interceptors.request.use(requestStartTimeInterceptor);

		const responseEndTimeInterceptor = (response) => {
			/** Calculate response time */
			response.duration = new Date().getTime() - response.config.metadata.startTime.getTime();
			return response;
		};
		this._client.interceptors.response.use(responseEndTimeInterceptor);
	}

	private _initCancelTokenErrorInterceptor(): void {
		const cancelTokenErrorInterceptor = (error) => {
			if (axios.isCancel(error)) return undefined;
			return Promise.reject(error);
		};

		this._client.interceptors.response.use((res) => res, cancelTokenErrorInterceptor);
	}

	private _initErrorInterceptor() {
		const responseInterceptor = (res) => res;

		const errorInterceptor = async (error) => {
			const isNetworkError = !error.response;
			const errResponse = isNetworkError ? error : error.response;
			const originalRequest = error.config;
			const delay = (ms) => new Promise((res) => setTimeout(res, ms));
			const isBadRequest = error.response && error.response.status != 401 && error.response.status < 500;
			if (originalRequest.requestRetries > 0 && !isBadRequest) {
				originalRequest.requestRetries -= 1;
				if (isNetworkError) {
					// this block helpfull now when the network is gone when a request is ongoing so we should retry it to be pushed to the offile queue , also is entered if the conection to server is failed so we retry 3 times to get work when serve is up again
					if (!error.config.skipNetworkErrorInterceptor) {
						logger.log('retrying request');
						logger.log(new Date());
						await delay(300);
						return this._client.request(originalRequest);
					}
				} else {
					// unauthorized but has refresh token
					if (
						this.hasAccessToken &&
						error.response.status === 401 &&
						!originalRequest._isRetry &&
						originalRequest.url != `${API_PATHS.AUTH_PATH}/refresh`
					) {
						originalRequest._isRetry = true;
						if (this._refreshTokenPromise) {
							const asyncInterval = async (ms: number) => {
								return new Promise((resolve) => {
									const checkRefreshCompleteInterval = setInterval(() => {
										if (this._refreshTokenPromise == null) {
											resolve(false);
											clearInterval(checkRefreshCompleteInterval);
										}
									}, ms);
								});
							};
							await asyncInterval(300);
							const authState: IAuthState = this._store.state[`${AUTH_NAMESPACE}`];
							const token = authState.idToken;
							if (token) originalRequest.headers['Authorization'] = 'Bearer ' + token;
							return this._client.request(originalRequest);
						} else {
							this.refreshToken(originalRequest);
						}
						return this._refreshTokenPromise;
					}
				}
			}
			if (error.response?.status >= 500 && !error.config.skipErrorNotification) {
				// if request retried more than 3 times and it's 500 then show error notification
				$notify({
					type: NotificationType.ERROR,
					title: 'Something went wrong. Please try again and help us report the problem in feedback.',
				});
			}

			return Promise.reject(errResponse);
		};

		this._client.interceptors.response.use(responseInterceptor, errorInterceptor);
	}

	private _initLocaleInterceptor() {
		const localeInterceptor = (config) => {
			if (config.method.toUpperCase() === 'GET' && config.url.startsWith(API_PATHS.ASSETMANAGER_PATH)) {
				const appState: AppState = this._store.state[`${APP_NAMESPACE}`];
				config.params = config.params || {};

				const locale = appState.locale ?? DEFAULT_APP_LOCALE;
				if (config.params instanceof URLSearchParams) {
					config.params.append('lang', locale);
				} else {
					config.params['lang'] = locale;
				}
			}
			return config;
		};
		this._client.interceptors.request.use(localeInterceptor);
	}

	registerConnectionStatusListener() {
		if (_PIXCAP_ENV.IS_LOCAL_APP_ENV) return;
		window.addEventListener('online', () => {
			this._isConnectedToNetwork = true;
			this._retryOfflineRequests();
			AppMutations.setNetworkStatusModal(this._store, {
				showModal: true,
				networkConnected: true,
			});
		});
		window.addEventListener('offline', () => {
			this._isConnectedToNetwork = false;
			AppMutations.setNetworkStatusModal(this._store, {
				showModal: true,
				networkConnected: false,
			});
		});
	}
}

var httpClient: HttpClient = null;

export function setHttpClient(httpClientInstance) {
	httpClient = httpClientInstance;
}
export { httpClient };
